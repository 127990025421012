import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Card, Notification } from "../../../components/layout";
import { Avatar } from "../../../requests/avatars/types";
import useAppActions from "../../../redux/actions/app";
import { FileField } from "../../../components/forms";
import useAvatars from "../../../requests/avatars";
import Styles from "./styles";



function Avatars() {
  const { setAppLoading } = useAppActions()
  const { createAvatar, listAvatars } = useAvatars();
  const [avatars, setAvatars] = useState<Avatar[]>([]);
  const [files, setFiles] = useState<FileList[]>([]);


  const getAvatars = async () => {
    await listAvatars()
      .then((resData) => {
        setAvatars(resData);
      }).catch(() => null);
  }

  const readFileContent = (file: File): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const result = event.target.result as ArrayBuffer;
          resolve(result);
        } else {
          reject(new Error("Error reading file content"));
        }
      };
      reader.onerror = () => {
        reject(new Error("Error reading file content"));
      };
      // Read file content as ArrayBuffer
      reader.readAsArrayBuffer(file);
    });
  }

  const uploadFiles = async (e: React.MouseEvent) => {
    e.preventDefault();
    setAppLoading(true);
    for (const file of files) {
      const formData = new FormData();
      formData.append("url", file[0])
      await createAvatar(formData)
    }
    setFiles([]);
    await getAvatars();
    setAppLoading(false);
    toast(
      <Notification type="success">
        Avatars uploaded!
      </Notification>
    );
  }


  useEffect(() => {
    getAvatars();
  }, [])



  return (
    <Styles className="page-wrapper">
      {/* Current Avatars */}
      <section id="CurrentAvatars">
        <div className="title jumbo">
          Current Avatars
        </div>
        <div className="grid avatar-list">
          {avatars.map((avatar) =>
            <Card className="avatar" key={avatar.id}>
              <img src={avatar.url} alt="" />
            </Card>
          )}
        </div>
      </section>

      {/* Upload New Avatars */}
      <section id="NewAvatars">
        <div className="title jumbo">
          Upload Avatars
        </div>
        <div className="uploaded-files flex column">
          {/* Show uploaded files here */}

          {/* Fix this */}
          {/* File Selector */}
          <FileField
            name="avatars"
            multiple
            placeholder="Drop some avatars here or click 'Select files'"
            onChange={(_, files) => {
              const tempFiles: FileList[] = [];
              const filesList = Array.from(files as FileList);
              const readFilePromises = filesList.map((file) => readFileContent(file));
              Promise.all(readFilePromises)
                .then((fileContents) => {
                  // Loop through fileContents and create File objects
                  fileContents.forEach((fileContent, index) => {
                    const { name, lastModified, size, type, webkitRelativePath } = filesList[index];
                    const fileOptions = { lastModified, size, type, webkitRelativePath };
                    // Create a new Blob with the file content
                    const dataFile = new File([fileContent], name, fileOptions);
                    const dataTransfer = new DataTransfer();
                    dataTransfer.items.add(dataFile);
                    tempFiles.push(dataTransfer.files);
                  });
                  // Set the state after processing all files
                  setFiles(tempFiles);
                })
                .catch((error) => {
                  console.error("Error reading file content:", error);
                });
            }}
          />
        </div>
      </section>
    </Styles>
  );
}

export default Avatars;