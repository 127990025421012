import { RouteType } from "../types";
import { assetPaths } from "./paths";
import {
  Avatars,
  CreateEditCrest, CrestList,
  TreeDetail, TreeList
} from "../../pages/Assets/pages";



/*****************/
/** Asset Routes */
export const assetRoutes: RouteType[] = [
  {
    path: assetPaths.TREES,
    element: <TreeList />
  },
  {
    path: assetPaths.AVATARS,
    element: <Avatars />
  },
  {
    path: assetPaths.CRESTS,
    element: <CrestList />
  }
]

/*******************/
/** Assets > Trees */
export const assetTreeRoutes: RouteType[] = [
  {
    path: assetPaths.TREE_DETAIL,
    element: <TreeDetail />
  }
]

/*******************/
/** Assets > Crests */
export const assetCrestRoutes: RouteType[] = [
  {
    path: assetPaths.ADD_CREST,
    element: <CreateEditCrest />
  },
  {
    path: assetPaths.EDIT_CREST,
    element: <CreateEditCrest />
  }
]