import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Styles from "./styles";



type NvLnk = {
  name: string;
  path: string;
  currentPath: boolean;
}

function PageBreadcrumbs() {
  const { pathname } = useLocation();
  const [crumbLinks, setCrumbLinks] = useState<NvLnk[]>([]);

  useEffect(() => {
    const paths = pathname.split("/").filter(path => path !== "");
    let currentPath = "";
    // Create a new array of breadcrumb links
    const newCrumbLinks = paths.map((path, index) => {
      currentPath = currentPath === "" ? path : `${currentPath}/${path}`;
      return {
        path: currentPath,
        name: path,
        currentPath: index === paths.length - 1
      };
    });

    setCrumbLinks(newCrumbLinks);
  }, [pathname])



  return (
    <Styles className="page-breadcrumbs">
      {crumbLinks.map((crumb, index) => !crumb.currentPath ? (
        <Link
          key={index}
          className="crumb"
          to={`/${crumb.path}`}
        >
          {crumb.name}
        </Link>
      ) : (
        <div key={index} className="crumb current">
          {crumb.name}
        </div>
      ))}
    </Styles>
  );
}

export default PageBreadcrumbs;