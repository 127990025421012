import axios from "axios"

import { ErrorResponse } from "../../types"
import { Subscription } from "./types"



/*  ################################## */
/** ## SUBSCRIPTION NETWORK REQUESTS # */
/*  ################################## */
const useSubscriptions = () => {
  const listSubscriptions = (params: Record<string, string>) => {
    return new Promise<Subscription[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/`,
        params
      }).then((res) => {
        const resData = res.data.results as Subscription[];
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    })
  }

  return {
    listSubscriptions
  }
}

export default useSubscriptions;