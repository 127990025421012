import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PlanIntervals, PlanRegions } from "../../../../constants/auth";
import filter_icon from "../../../../assets/vector-images/generic/filter.svg";
import { billingPaths } from "../../../../routes/billing/paths";
import { useQueryParams } from "../../../../utils/components";
import { SelectField } from "../../../../components/forms";
import { decimalPrice } from "../../../../utils/numbers";
import { Plan } from "../../../../requests/plans/types";
import { Loader } from "../../../../components/layout";
import { useInput } from "../../../../utils/forms";
import usePlans from "../../../../requests/plans";
import Styles from "./styles";



function PlanList() {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const interval = queryParams.get("interval") || "year";
  const region = queryParams.get("region") || "";
  const { listPlans } = usePlans();
  const [plans, setPlans] = useState<Plan[]>([]);
  const {
    value: regionValue,
    setValue: setRegionValue,
  } = useInput<string>({
    initialValue: region,
    selectValues: PlanRegions.map(option => option.value)
  });
  const {
    value: intervalValue,
    setValue: setIntervalValue,
  } = useInput<string>({
    initialValue: interval,
    selectValues: PlanIntervals.map(option => option.value)
  });


  const updatePlanRegion = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setRegionValue(value);
    updateFilters("region", value);
  }

  const updateInterval = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setIntervalValue(value);
    updateFilters("interval", value);
  }

  const getPlans = () => {
    const params = {
      search,
      page,
      pageSize,
      interval,
      region
    }
    listPlans(params)
      .then((results) => {
        setPlans(results)
      }).catch(() => null);
  }


  useEffect(() => {
    getPlans();
  }, [locationSearch])



  return (
    <Styles>
      {/* Header */}
      <header className="filters flex justify-between align-center">
        <div className="flex align-center select-filters">
          <SelectField
            options={PlanRegions}
            value={regionValue}
            placeholder="All Regions"
            onChange={updatePlanRegion}
            icon={filter_icon}
          />
          <SelectField
            options={PlanIntervals}
            value={intervalValue}
            onChange={updateInterval}
            icon={filter_icon}
          />
        </div>
      </header>
      {!plans.length ? (
        <Loader />
      ) : (
        <div className="page-wrapper plans">
          {plans.map((plan) =>
            <div className="card plan flex column" key={plan.id}>
              <div className="head">
                {/* Plan Name and Price */}
                <h2 className="name">
                  {plan.name}
                </h2>
                <div className="price flex">
                  {plan.region === "UK" ? "£" : "$"}
                  {decimalPrice(plan.planPrices[0].amount)}
                  <div className="interval">
                    {`/ ${plan.planPrices[0].interval}`}
                  </div>
                </div>
              </div>
              {/* Plan Description */}
              <div className="content">
                <div className="title">
                  {plan.featureTitle}
                </div>
                <ul className="features flex column">
                  {plan.planFeatures.map(feature =>
                    <li className="feature" key={feature.id}>
                      {feature.description}
                    </li>
                  )}
                </ul>
              </div>
              <Link
                className="button choose-plan"
                to={billingPaths.planDetail(plan.id)}
              >
                View Plan
              </Link>
            </div>
          )}
        </div>
      )}
    </Styles>
  );
}

export default PlanList;