import { Outlet, useOutletContext } from "react-router-dom";

import PageHeader, { PageRoute } from "../../components/layout/PageHeader";
import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { billingPaths } from "../../routes/billing/paths";



const Billing = () => {
  const { user } = useOutletContext<AuthRouteContext>();
  const pageRoutes: PageRoute[] = [
    {
      name: "Plans",
      path: billingPaths.LIST_PLANS,
    },
    {
      name: "Discounts",
      path: billingPaths.LIST_DISCOUNTS,
    },
    {
      name: "Subscriptions",
      path: billingPaths.LIST_SUBSCRIPTIONS,
    }
  ]



  return (
    <div className="root-content">
      <PageHeader
        title="Billing"
        pageRoutes={pageRoutes}
      />
      <Outlet context={{ user }} />
    </div>
  );
}

export default Billing;