import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { decimalPrice, getDiscountedPrice } from "../../../../utils/numbers";
import { getVerboseStoreName } from "../../../../constants/auth";
import { billingPaths } from "../../../../routes/billing/paths";
import { useQueryParams } from "../../../../utils/components";
import { currencySymbol } from "../../../../utils/strings";
import {
  PlanPriceDiscount
} from "../../../../requests/planPriceDiscounts/types";
import { Card } from "../../../../components/layout";
import usePlanPriceDiscounts
  from "../../../../requests/planPriceDiscounts";
import Styles from "./styles";



function DiscountList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const { listPlanPriceDiscounts } = usePlanPriceDiscounts();
  const [discounts, setDiscounts] = useState<PlanPriceDiscount[]>([]);


  const getDiscounts = () => {
    const params = {
      search,
      page,
      pageSize
    }
    listPlanPriceDiscounts(params)
      .then((results) => {
        setDiscounts(results)
      }).catch(() => null)
  }

  useEffect(() => {
    getDiscounts()
  }, [locationSearch])



  return (
    <Styles>
      {/* Filters */}
      <div className="filters flex justify-end align-center">
        <Link
          to={billingPaths.ADD_DISCOUNT}
          className="button sm mid-radius">
          + New
        </Link>
      </div>

      {/* Main Content */}
      <div className="page-wrapper">
        <div className="flex wrap discount-list">
          {discounts.map((discount, index) =>
            <Card key={`${discount.id}-${index}`}>
              <div className="card-title">
                {`${discount.planPrice.plan.name} (${discount.planPrice.plan.region})`}
              </div>
              <div className="grey-text">
                {discount.description}
              </div>
              <div className="text">
                {`${currencySymbol(discount.planPrice.currency)
                  }${decimalPrice(discount.planPrice.amount)}${""
                  } / ${discount.planPrice.interval
                  } - ${getVerboseStoreName(discount.planPrice.store)}`}
              </div>
              <div className="main-value flex">
                {discount.percentage}%
                {" - "}
                <div className="grey-text">
                  {currencySymbol(
                    discount.planPrice.currency
                  )}
                  {getDiscountedPrice(
                    discount.planPrice.amount,
                    discount.percentage
                  )}
                </div>
              </div>
              <Link
                className="button mt-2"
                to={billingPaths.editDiscount(discount.id)}
              >
                Edit Discount
              </Link>
            </Card>
          )}
        </div>
      </div>
    </Styles>
  );
}

export default DiscountList;