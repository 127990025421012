import { Outlet, useOutletContext } from "react-router-dom";

import PageHeader, { PageRoute } from "../../components/layout/PageHeader";
import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { profilePaths } from "../../routes/profile/paths";
import Styles from "./styles";



const Profile = () => {
  const { user } = useOutletContext<AuthRouteContext>();
  const pageRoutes: PageRoute[] = [
    {
      name: "Personal",
      path: profilePaths.PERSONAL,
    },
    {
      name: "Manage",
      path: profilePaths.MANAGE,
    }
  ]


  return (
    <Styles className="root-content">
      <PageHeader
        title="Profile"
        pageRoutes={pageRoutes}
      />
      <Outlet context={{ user }} />
    </Styles>
  )
}

export default Profile;