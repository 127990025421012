import { MouseEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";

import sync_icon from "../../../assets/vector-images/generic/sync.svg";
import bag from "../../../assets/vector-images/panel/bag.svg";
import { CountrySelect } from "../../../components/forms";
import { useQueryParams } from "../../../utils/components";
import useProducts from "../../../requests/products";
import Table from "../../../components/layout/Table";
import { useInput } from "../../../utils/forms";
import useProductListUtils from "./utils";
import {
  PageHeader, Notification
} from "../../../components/layout";
import Styles from "./styles";



const Products = () => {
  const { locationSearch, queryParams, updateFilters } = useQueryParams();
  const search = queryParams.get("search") || "";
  const country = queryParams.get("country") || "";
  const page = queryParams.get("page") || "1";
  const pageSize = queryParams.get("pageSize") || "";
  const { buildProducts } = useProducts();
  const {
    updateProductList, tableColumns, tableData
  } = useProductListUtils({ search, country, page, pageSize });
  const [syncing, setSyncing] = useState<boolean>(false);
  const {
    value: countryValue,
    stringChange: countryCh,
    setValue: setCountryValue,
  } = useInput({
    initialValue: country || ""
  });
  const {
    value: countrySearch,
    stringChange: countrySearchChange,
    setValue: countrySearchValue
  } = useInput({
    initialValue: ""
  });

  /**************************************/
  /** Rebuild product list from vendors */
  const syncProducts = (e: MouseEvent) => {
    e.preventDefault();
    setSyncing(true);
    buildProducts().then(() => {
      updateProductList();
      toast(
        <Notification type="success">
          Product list updated!
        </Notification>
      );
    }).finally(() => {
      setSyncing(false);
    });
  }


  useEffect(() => {
    updateProductList();
  }, [locationSearch])

  useEffect(() => {
    updateFilters("country", countryValue);
  }, [countryValue])



  return (
    <Styles className="root-content">
      <PageHeader
        title="Products"
        breadcrumbs
        showSearch={true}
      >
        <CountrySelect
          country={countryValue}
          countryCh={countryCh}
          setCountry={setCountryValue}
          search={countrySearch}
          searchCh={countrySearchChange}
          setSearch={countrySearchValue}
        />
        <button
          className={`button transparent sync ${syncing ? "syncing" : ""}`}
          onClick={syncProducts}
        >
          <img src={sync_icon} alt="" />
          <div>Sync</div>
        </button>
      </PageHeader>
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={tableColumns}
          noData={{
            icon: bag,
            title: "No products available",
          }}
        />
      </div>
    </Styles>
  );
}

export default Products;