import { useEffect, useState } from "react";

import { Subscription } from "../../../../requests/subscriptions/types";
import useSubscriptions from "../../../../requests/subscriptions";
import { useQueryParams } from "../../../../utils/components";



function SubscriptionList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const { listSubscriptions } = useSubscriptions();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);


  const getSubscriptions = () => {
    const params = {
      search,
      page,
      pageSize
    }
    listSubscriptions(params)
      .then((results) => {
        setSubscriptions(results)
      }).catch(() => null)
  }

  useEffect(() => {
    getSubscriptions()
  }, [locationSearch])



  return (
    <div>
      {subscriptions.length}
    </div>
  );
}

export default SubscriptionList;