import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { ClanCrest, PageHeader, Table } from "../../../../components/layout";
import { TableColumn } from "../../../../components/layout/Table/types";
import { accountPaths } from "../../../../routes/accounts/paths";
import { usePageControls } from "../../../../utils/components";
import { Family } from "../../../../requests/families/types";
import { Clan } from "../../../../requests/clans/types";
import { URLParams } from "../../../../constants";
import useClans from "../../../../requests/clans";
import Styles from "./styles";



function ClanDetail() {
  const { clanId } = useParams<URLParams>();
  const { previousPage } = usePageControls();
  const { getClan, listFamiliesInClan } = useClans();
  const [clan, setClan] = useState<Clan>();
  const [families, setFamilies] = useState<Family<null>[]>([]);


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      content: (family: Family<null>) => (
        <Link
          to={accountPaths.familyDetail(family.id)}
          className="table-title"
        >
          {family.name ? (
            <>
              {family.crest ? (
                <img
                  src={family.crest.url}
                  alt=""
                  className="crest"
                />
              ) : null}
              <div>{family.name}</div>
            </>
          ) : (
            <div>{family.id}</div>
          )}
        </Link >
      )
    },
    {
      title: "Members",
      key: "members",
      content: (family: Family<null>) => (
        family.userCount
      )
    }
  ]


  useEffect(() => {
    if (!clanId) {
      previousPage();
      return;
    };
    getClan(clanId)
      .then((resData) => {
        setClan(resData);
        listFamiliesInClan(clanId)
          .then((families) => {
            setFamilies(families);
          });
      });
  }, [])



  return (
    <Styles className="root-content">
      <PageHeader
        breadcrumbs
        className="username-link"
        title={clan
          ? clan.name ? clan.name : clan.id
          : "Clans's Details"
        }
      />
      {clan ? (
        <div className="page-wrapper">
          <div className="merged-table">
            <ClanCrest clan={clan} />
            <Table
              columns={columns}
              results={families}
            />
          </div>
        </div>
      ) : null}
    </Styles>
  );
}

export default ClanDetail;