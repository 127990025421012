import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { useFormNavigation } from "../../../components/forms/FormSteps";
import { FormStep } from "../../../components/forms/FormSteps/types";
import { createQuiz } from "../../../components/forms/QuizQuestion";
import { FormValues } from "../../../components/forms/Form/types";
import { Desert, Mission, Quiz } from "../../../types/core";
import { PageHeader } from "../../../components/layout";
import useAppActions from "../../../redux/actions/app";
import { FormSteps } from "../../../components/forms";
import useMissions from "../../../requests/missions";
import PreviewMissionChanges from "./PreviewForm";
import { URLParams } from "../../../constants";
import useMissionUtils from "./utils";
import SetupForm from "./SetupForm";
import QuizForm from "./QuizForm";
import Styles from "./styles";



function CreateEditMission() {
  const { missionId } = useParams<URLParams>();
  const { setDeserts } = useAppActions();
  const { getMission, listDeserts } = useMissions();
  const { currentStep, ...formNavigation } = useFormNavigation(0);
  const [mission, setMission] = useState<Mission>();
  const [quizzes, setQuizzes] = useState<Quiz[]>([createQuiz()]);
  const [formValues, setFormValues] = useState<FormValues>({});
  const { confirmChanges } = useMissionUtils({
    formValues, missionId, mission, quizzes
  });


  /***************/
  /** Form Steps */
  const formSteps: FormStep[] = [
    {
      title: "Setup",
      content: (
        <SetupForm
          {...formNavigation}
          values={formValues}
          setValues={setFormValues}
        />
      )
    },
    {
      title: "Additional Reading & Quizzes",
      content: (
        <QuizForm
          {...formNavigation}
          values={formValues}
          setValues={setFormValues}
          quizzes={quizzes}
          setQuizzes={setQuizzes}
        />
      )
    },
    {
      title: "Preview",
      content: (
        <PreviewMissionChanges
          {...formNavigation}
          missionId={missionId}
          quizzes={quizzes}
          values={formValues}
          setValues={setFormValues}
          confirmChanges={confirmChanges}
        />
      )
    }
  ]


  useEffect(() => {
    if (!missionId) return;
    getMission(missionId).then(async (resData) => {
      const mission = resData as Mission;
      if (!mission) return;
      const {
        tree, createdAt, updatedAt, quizzes,
        ...formFields
      } = mission;
      setMission(mission);
      setQuizzes(quizzes);
      setFormValues((prevState) => ({
        ...prevState,
        ...formFields,
        desertId: tree.desertId,
        region: tree.region
      }));
    });
  }, [missionId])

  useEffect(() => {
    // Initialize
    listDeserts().then((resData) => {
      const deserts = resData as Desert[];
      setDeserts(deserts);
    });
  }, [])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        breadcrumbs
        title={missionId
          ? "Edit Mission"
          : "Add Mission"
        }
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <FormSteps
          currentStep={currentStep}
          steps={formSteps}
        />
      </div>
    </Styles>
  );
}

export default CreateEditMission;