import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import users_icon from "../../../../assets/vector-images/panel/users.svg";
import user_icon from "../../../../assets/vector-images/generic/user-icon.svg";
import { accountPaths } from "../../../../routes/accounts/paths";
import { useQueryParams } from "../../../../utils/components";
import { KycWithUser } from "../../../../requests/kyc/types";
import { PaginatedResponse } from "../../../../types";
import { Table } from "../../../../components/layout";
import useKyc from "../../../../requests/kyc";



function KycList() {
  const { locationSearch, queryParams } = useQueryParams();
  const pageSize = queryParams.get("pageSize") || "";
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "";
  const { listKyc } = useKyc();
  const [tableData, setTableData] = useState<
    PaginatedResponse<KycWithUser>
  >();

  const columns = [
    {
      title: "Name",
      key: "id",
      content: (kyc: KycWithUser) => (
        <Link
          to={accountPaths.kycDetail(kyc.id)}
          className="table-title"
        >
          <img
            alt=""
            src={(kyc.user.avatar?.url)
              ? kyc.user.avatar.url
              : user_icon
            }
          />
          <div>
            {kyc.user.firstName} {kyc.user.middleName} {kyc.user.lastName}
          </div>
        </Link>
      )
    },
    {
      title: "Status",
      key: "status",
      content: (kyc: KycWithUser) => (
        <div className="text">
          {kyc.status.charAt(0).toUpperCase() +
            kyc.status.substring(1)}
        </div>
      )
    },
    {
      title: "Required Document",
      key: "requiredDocument",
      content: (kyc: KycWithUser) => (
        <div className="text">
          {kyc.requiredDocument.charAt(0).toUpperCase() +
            kyc.requiredDocument.substring(1)}
        </div>
      )
    },
    {
      title: "Created At",
      key: "createdAt",
      content: (kyc: KycWithUser) => (
        <div className="grey">
          {moment(kyc.createdAt).format("Do MMM YYYY, hh:mma")}
        </div>
      )
    },
  ]

  /*****************/
  /** Get all data */
  const syncData = () => {
    const params = {
      search,
      page,
      pageSize
    }
    listKyc(params)
      .then((data) => {
        setTableData(data);
      }).catch(() => null);
  }


  useEffect(() => {
    syncData();
  }, [locationSearch])



  return (
    <div className="page-wrapper">
      <Table
        {...tableData}
        columns={columns}
        noData={{
          icon: users_icon,
          title: "No KYC Records"
        }}
      />
    </div>
  );
}

export default KycList;