import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { FormField, FormValues } from "../../../../components/forms/Form/types";
import usePlanPriceDiscounts from "../../../../requests/planPriceDiscounts";
import Form from "../../../../components/forms/Form";
import { SelectFieldOption } from "../../../../components/forms/SelectField";
import { useAppModal } from "../../../../components/layout/AppModal/utils";
import { PageHeader, Notification } from "../../../../components/layout";
import { getVerboseStoreName } from "../../../../constants/auth";
import { billingPaths } from "../../../../routes/billing/paths";
import { currencySymbol } from "../../../../utils/strings";
import { decimalPrice } from "../../../../utils/numbers";
import {
  PlanPriceDiscountCreateArgs
} from "../../../../requests/planPriceDiscounts/types";
import { URLParams } from "../../../../constants";
import usePlans from "../../../../requests/plans";



function CreateEditDiscount() {
  const { planPriceDiscountId } = useParams<URLParams>();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { setAppModal, closeModal } = useAppModal();
  const { listPlans } = usePlans();
  const {
    createPlanPriceDiscount, getPlanPriceDiscount,
    updatePlanPriceDiscount, deletePlanPriceDiscount
  } = usePlanPriceDiscounts();
  const [formValues, setFormValues] = useState<FormValues>({});
  const [planPriceOptions, setPlanPriceOptions] = useState<
    SelectFieldOption[]
  >([]);


  /*****************/
  /** Save changes */
  const saveDetails = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    const data = formValues as PlanPriceDiscountCreateArgs;
    if (planPriceDiscountId) {
      updatePlanPriceDiscount(planPriceDiscountId, data)
        .then(() => {
          exitPage();
        }).catch(() => null);
    } else {
      createPlanPriceDiscount(data)
        .then(() => {
          exitPage();
        }).catch(() => null);
    }
  }

  /********************/
  /** Delete Discount */
  const deleteDiscount = (e: MouseEvent, id: string | number) => {
    e.preventDefault();
    deletePlanPriceDiscount(id)
      .then(() => {
        toast(
          <Notification type="success">
            Discount Successfully Deleted!
          </Notification>
        );
        navigate(billingPaths.LIST_DISCOUNTS);
      }).catch(() => null);
  }

  /***********************************/
  /** Confirmation Discount Deletion */
  const confirmDeletion = (e: MouseEvent) => {
    e.preventDefault();
    if (!planPriceDiscountId) return;
    setAppModal({
      title: "Delete Discount",
      children: "Are you sure you want to delete this Discount?",
      controls: (
        <div className="modal-controls">
          <button
            className="button white-primary"
            onClick={() => { closeModal() }}
          >
            No, Cancel
          </button>
          <button
            className="button error"
            type="button"
            onClick={(e) => {
              closeModal();
              deleteDiscount(e, planPriceDiscountId);
            }}
          >
            Yes, Delete
          </button>
        </div>
      ),
      cancelCallback: () => {
        closeModal();
      }
    });
  }

  /***************************************/
  /** Confirmation Modal & Page Redirect */
  const exitPage = () => {
    setAppModal({
      title: "Discount Saved",
      children: (
        `${planPriceDiscountId
          ? "Changes Saved!"
          : "Discount successfully added!"
        }`
      ),
      cancelCallback: () => {
        navigate(billingPaths.LIST_DISCOUNTS);
      }
    });
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "text",
      name: "description",
      label: "Description",
      groupClass: "field-group 1"
    },
    {
      type: "select",
      name: "planPriceId",
      label: "Plan",
      options: planPriceOptions,
      groupClass: "field-group 1"
    },
    {
      type: "number",
      name: "percentage",
      label: "Discount %",
      max: "100",
      placeholder: "Percentage of Discount",
      groupClass: "field-row 1"
    },
    {
      type: "number",
      name: "daysDuration",
      label: "Duration in Days",
      placeholder: "Duration of the discount in days",
      groupClass: "field-row 1"
    },
    {
      type: "text",
      name: "code",
      label: "Code",
      placeholder: "Discount Code",
      groupClass: "field-group 2"
    }
  ]

  useEffect(() => {
    if (!planPriceDiscountId) return;
    getPlanPriceDiscount(planPriceDiscountId)
      .then((discount) => {
        const {
          description, planPriceId, percentage, daysDuration, code
        } = discount;
        setFormValues((prevState) => ({
          ...prevState,
          planPriceId: planPriceId.toString(),
          description,
          percentage,
          daysDuration,
          code
        }));
      }).catch(() => null);
  }, [planPriceDiscountId])

  useEffect(() => {
    listPlans({})
      .then((plans) => {
        const planPrices = [];
        for (const plan of plans) {
          for (const planPrice of plan.planPrices) {
            const planPriceOption: SelectFieldOption = {
              value: `${planPrice.id}`,
              label: (
                `${plan.name} (${plan.region}) - ${""
                }${currencySymbol(planPrice.currency)
                }${decimalPrice(planPrice.amount)}${""
                } / ${planPrice.interval
                } - ${getVerboseStoreName(planPrice.store)}`
              )
            }
            planPrices.push(planPriceOption);
          }
        }
        setPlanPriceOptions(planPrices);
      }).catch(() => null);
  }, [])



  return (
    <div className="root-content">
      {/* Header */}
      <PageHeader
        breadcrumbs
        title={`${planPriceDiscountId
          ? "Edit Discount"
          : "Add Discount"}`
        }
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveDetails}
          ref={formRef}
        >
          <div className="flex">
            <button
              className="button submit auto-width"
              type="submit"
            >
              {planPriceDiscountId
                ? "Save Changes"
                : "Create Discount"
              }
            </button>
            {planPriceDiscountId ? (
              <button
                className="button auto-width submit error"
                type="button"
                onClick={confirmDeletion}
              >
                Delete Discount
              </button>
            ) : null}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default CreateEditDiscount;