import { useState, useRef, MouseEvent } from "react";
import { NavLink, Link } from "react-router-dom";

import menu from "../../../assets/vector-images/generic/menu.svg";
import { missionPaths } from "../../../routes/missions/paths";
import { getVerboseUserType } from "../../../constants/auth";
import { useHiddenOutside } from "../../../utils/components";
import logo from "../../../assets/vector-images/sona/logo.svg";
import useAppActions from "../../../redux/actions/app";
import { User } from "../../../requests/users/types";
import { rootPaths } from "../../../routes/paths";
import { navLinks } from "./utils";
import Styles from "./styles";



type Props = {
  user: User;
}

function Sidebar({
  user
}: Props) {
  const { logoutUser } = useAppActions();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const userPopup = useRef<HTMLDivElement>(null);
  const {
    visible: dropdown,
    setVisible: setDropdown
  } = useHiddenOutside(userPopup);

  const logout = () => {
    logoutUser();
  }

  const toggleMenu = (e: MouseEvent) => {
    e.preventDefault();
    setMenuOpen(!menuOpen);
  }



  return (
    <Styles id="SideBar">
      <div className="top-bar">
        {/* <button
          style={{ backgroundImage: `url(${menu})` }}
          onClick={toggleMenu}
          id="menu"
        /> */}
        <Link to={rootPaths.HOMEPAGE} className="home-link-icon">
          <img id="logo" src={logo} alt="" />
        </Link>
      </div>
      {/* Nav Links */}
      <nav className="nav-links">
        {navLinks.map((navlink, index) =>
          <NavLink
            to={navlink.path}
            className={({ isActive }) => `nav-link
            ${isActive ? ' active' : ""}`}
            key={index}
          >
            <div className="icon">{navlink.icon}</div>
            <div className="text">{navlink.name}</div>
          </NavLink>
        )}
      </nav>

      {/* User Panel */}
      <div id="userPanel" ref={userPopup}>
        <button
          className="user"
          onClick={() => setDropdown(!dropdown)}
        >
          {user.avatar ? (
            <img
              className="avatar"
              src={user.avatar.url}
              alt=""
            />
          ) : (
            <div className="avatar placeholder">
              {user.firstName?.charAt(0)}
              {user.lastName?.charAt(0)}
            </div>
          )}
          <div className="details">
            <div className="name">
              {user.firstName} {user.lastName}
            </div>
            <div className={`role ${user.userType}`}>
              {getVerboseUserType(user.userType)}
            </div>
          </div>
        </button>
        {dropdown &&
          <button
            className="user-dropdown"
            onClick={logout}
          >
            Logout
          </button>
        }
      </div>
    </Styles>
  );
}

export default Sidebar;