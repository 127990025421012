import styled from "styled-components";

import { colors } from "../../../styles";



export const Styles = styled.div`
    .model-detail {
      ul {
        border: 1px solid ${colors.grey1};
        border-radius: 7px;
        overflow: hidden;
        li {
          padding: 1rem;
          &:not(:last-child) {
            border-bottom: 1px solid ${colors.grey1};
          }
          &:nth-child(odd) {
            background-color: ${colors.grey8};
          }
          &:nth-child(odd) {
            background-color: ${colors.grey4};
          }
          .title {
            font-size: 0.9rem;
            color: ${colors.grey6};
          }
          .content {
            font-weight: bold;
            font-size: 1rem;
          }
        }
      }
    }

`;

export default Styles;