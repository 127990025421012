import { Dispatch, SetStateAction } from "react";

import { FormField, FormValues } from
  "../../../../components/forms/Form/types";



type EditPlanUtilsArgs = {
  formValues: FormValues;
  setFeatures: Dispatch<SetStateAction<string[]>>;
}

export const useEditPlan = (args: EditPlanUtilsArgs) => {
  const { formValues, setFeatures } = args;

  /*********************/
  /** Plan Form Fields */
  const formFields: FormField[] = [
    {
      type: "number",
      name: "maxLearners",
      label: "Max Learners / Children",
      max: 10,
      placeholder: "Max no. of Learners in the Family",
      groupClass: "field-group"
    },
    {
      type: "number",
      name: "maxParents",
      max: 10,
      label: "Max Family Admins / Parents",
      placeholder: "Max no. of Parents in the Family",
      groupClass: "field-group"
    },
    {
      type: "number",
      name: "maxCards",
      label: "Maximum Cards",
      max: 10,
      placeholder: "Max no. of cards for the card program",
      groupClass: "field-group"
    }
  ]


  const updateFeatures = () => {
    const {
      maxParents: maxP, maxLearners: maxL, maxCards: maxC
    } = formValues;
    const maxParents = parseInt(maxP);
    const maxLearners = parseInt(maxL);
    const maxCards = parseInt(maxC);
    const featureList = [];
    if (maxParents && !maxLearners) {
      featureList.push(
        `${maxParents} ${maxParents > 1 ? "Users" : "User"}`
      );
    }
    else if (maxParents && maxLearners) {
      featureList.push(
        (
          `${maxLearners} ${(maxLearners > 1) ? (
            "Learner Accounts"
          ) : (
            "Learner Account"
          )}`
        ),
        (
          `${maxParents} ${(maxParents > 1) ? (
            "Free Parent (Admin) Accounts"
          ) : (
            "Free Parent (Admin) Account"
          )}`
        ),
        "Family Leaderboard"
      );
    }
    featureList.push(
      "Daily Affirmation Tool",
      "Access to Study Missions",
      "Community Interaction"
    )
    if (maxCards) {
      featureList.push(
        (
          `${maxCards} Free Learner Prepaid ${(maxCards > 1) ? (
            "Cards"
          ) : (
            "Card"
          )} (UK Only)`
        ),
        "Free In-app Money Mangement (UK Only)"
      );
    }
    setFeatures(featureList);
  }


  return {
    formFields,
    updateFeatures
  }
}