import { useEffect, useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";

import useAffirmations from "../../../requests/affirmations";
import { useQueryParams } from "../../../utils/components";
import { AllRegionOptions } from "../../../constants/core";
import { PageRoute } from "../../../components/layout/PageHeader";
import cast_icon from "../../../assets/vector-images/panel/cast.svg";

import filter from "../../../assets/vector-images/generic/filter.svg";
import { PaginatedResponse } from "../../../types";
import { Affirmation } from "../../../types/core";
import { useInput } from "../../../utils/forms";
import { Table, PageHeader } from "../../../components/layout";
import { SelectField } from "../../../components/forms";
import { affirmationPaths } from "../../../routes/affirmations/paths";
import Styles from "./styles";
import useAffirmationListUtils from "./utils";



type Props = {
  isPublished?: boolean;
}

function AffirmationList({ isPublished = true }: Props) {
  const {
    updateFilters, locationSearch, queryParams
  } = useQueryParams();
  const search = queryParams.get("search") || "";
  const region = queryParams.get("region") || "";
  const page = queryParams.get("page") || "1";
  const pageSize = queryParams.get("pageSize") || "";
  const { listAffirmations } = useAffirmations();
  const { tableColumns } = useAffirmationListUtils();
  const [tableData, setTableData] = useState<PaginatedResponse<Affirmation>>();
  const [publishedCount, setPublishedCount] = useState<number | null>(null);
  const [draftCount, setDraftCount] = useState<number | null>(null);
  const {
    value: regionValue,
    setValue: setRegion,
  } = useInput({
    initialValue: region ? region : "",
    selectValues: AllRegionOptions.map(option => option.value)
  });

  const updateRegion = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setRegion(value);
    updateFilters("region", value);
  }

  /****************/
  /** Page Routes */
  const pageRoutes: PageRoute[] = [
    {
      name: (
        <div className="flex align-center">
          Published
          {publishedCount ? (
            <span className="count">{publishedCount}</span>
          ) : null}
        </div>
      ),
      path: affirmationPaths.PUBLISHED,
    },
    {
      name: (
        <div className="flex align-center">
          Drafts
          {draftCount ? (
            <span className="count">{draftCount}</span>
          ) : null}
        </div>
      ),
      path: affirmationPaths.DRAFT
    }
  ]

  /***********************************/
  /** List Affirmations with filters */
  const getAffirmationList = () => {
    const params = {
      isPublished,
      region,
      search,
      page,
      pageSize
    };
    listAffirmations(params)
      .then((data) => {
        setTableData(data);
        if (isPublished) {
          setPublishedCount(data.count);
          setDraftCount(null);
        } else {
          setDraftCount(data.count);
          setPublishedCount(null);
        }
      }).catch(() => null)
  }


  useEffect(() => {
    getAffirmationList();
  }, [locationSearch])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        title="Affirmations"
        pageRoutes={pageRoutes}
        showSearch={true}
      >
        <SelectField
          options={AllRegionOptions}
          placeholder="All Regions"
          icon={filter}
          value={regionValue}
          onChange={updateRegion}
        />
        <Link
          to={affirmationPaths.CREATE}
          className="button sm mid-radius">
          New Affirmation
        </Link>
      </PageHeader>

      {/* Main Content */}
      <div className="page-wrapper">
        <Table
          {...tableData}
          columns={tableColumns}
          noData={{
            icon: cast_icon,
            title: "No Affirmations"
          }}
        />
      </div>
    </Styles>
  );
}

export default AffirmationList;