import { FormEvent, ReactNode, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import { useQueryParams } from "../../../utils/components";
import { useInput } from "../../../utils/forms";
import { TextField } from "../../forms";
import Styles from "./styles";



export type PageRoute = {
  name: ReactNode;
  path: string;
}

type Props = {
  title: ReactNode;
  pageRoutes?: PageRoute[];
  showSearch?: boolean;
  children?: ReactNode;
  className?: string;
  breadcrumbs?: boolean;
}

type NvLnk = {
  name: string;
  path: string;
  currentPath: boolean;
}

function PageHeader({
  title,
  pageRoutes,
  breadcrumbs,
  className,
  showSearch,
  children
}: Props) {
  const { pathname } = useLocation();
  const { queryParams, updateFilters } = useQueryParams();
  const search = queryParams.get("search") || "";
  const [crumbLinks, setCrumbLinks] = useState<NvLnk[]>([]);
  const [searchDelay, setSearchDelay] = useState<any>();
  const {
    value: searchValue,
    stringChange: searchValueCh
  } = useInput({
    initialValue: decodeURIComponent(search) ?? ""
  });

  const searchQuery = (e?: FormEvent) => {
    if (e) e.preventDefault();
    updateFilters("search", searchValue);
  }

  useEffect(() => {
    const paths = pathname.split("/").filter(path => path !== "");
    let currentPath = "";
    // Create a new array of breadcrumb links
    const newCrumbLinks = paths.map((path, index) => {
      currentPath = currentPath === "" ? path : `${currentPath}/${path}`;
      return {
        path: currentPath,
        name: path,
        currentPath: index === paths.length - 1
      };
    });
    setCrumbLinks(newCrumbLinks);
  }, [pathname])


  useEffect(() => {
    if (!search && !searchValue) return;
    clearTimeout(searchDelay);
    setSearchDelay(setTimeout(() => {
      searchQuery();
    }, 1000));
  }, [searchValue])



  return (
    <Styles
      className={`root-header flex column ${className
        ? className
        : ""}`
      }
    >
      <div className="primary flex">
        <h1 className="title">
          {breadcrumbs ? (
            <div className="breadcrumbs">
              {crumbLinks.map((crumb, index) => !crumb.currentPath ? (
                <Link
                  key={index}
                  className="page"
                  to={`/${crumb.path}`}
                >
                  {crumb.name}
                </Link>
              ) : (
                <div key={index} className="page current">
                  {title}
                </div>
              ))}
            </div>
          ) : title}
        </h1>
        {pageRoutes && pageRoutes?.length ? (
          <nav className="tab-links">
            {pageRoutes.map((route, index) =>
              <NavLink
                to={route.path}
                className={({ isActive }) =>
                  `tab-link ${isActive ? ' active' : ""}`}
                key={index}
              >
                {route.name}
              </NavLink>
            )}
          </nav>
        ) : null}
        {showSearch ? (
          <form
            className="search-form"
            onSubmit={searchQuery}
            noValidate
          >
            <TextField
              value={searchValue}
              onChange={searchValueCh}
              placeholder="Search"
            />
          </form>
        ) : null}
      </div>
      {children ? (
        <div className="flex justify-between secondary">
          {children}
        </div>
      ) : null}
    </Styles>
  )
}

export default PageHeader;