import styled from "styled-components";

import { colors, properties } from "../../../styles";



const Styles = styled.div`
  background-color: ${colors.grey5};

  #Console {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: ${properties.maxPageWidth};
    margin: 0 auto;
    overflow: hidden;
  }
`;

export default Styles;