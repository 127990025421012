import axios from "axios";

import { useAppModal } from "../../components/layout/AppModal/utils";
import { ErrorResponse, PaginatedResponse } from "../../types";
import useAppActions from "../../redux/actions/app";
import { useAppState } from "../../redux/state";
import { User, SignInData } from "./types";



/*  ########################## */
/** ## USER NETWORK REQUESTS # */
/*  ########################## */
const useUsers = () => {
  const { setAppLoading, saveUser, saveToken } = useAppActions();
  const { user } = useAppState();
  const { setAppModal } = useAppModal();

  /***************************************/
  /** Sign in with Username and Password */
  const signIn = (data: SignInData) => {
    return new Promise<boolean | ErrorResponse>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/auth/sign-in`,
        method: "post",
        data
      }).then((res) => {
        saveUser(res.data as User);
        saveToken(res.data.authToken.token);
        resolve(true);
      }).catch((error) => {
        reject(error as ErrorResponse);
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }

  /*************************************/
  /** Get the logged in User's details */
  const getMe = () => {
    return new Promise<User | null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/users/me`
      }).then((res) => {
        const user = res.data as User;
        saveUser(user);
        resolve(user);
      }).catch(() => {
        reject(null);
      })
    })
  }

  /**********************/
  /** Update User by ID */
  const updateUser = (data: any) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/collaborators/${user?.id}/`,
        method: "patch",
        data
      }).then((res) => {
        const user = res.data as User;
        resolve(user);
      }).catch((error) => {
        reject(error)
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }


  /**************/
  /** List Users */
  const listUsers = (params?: any) => {
    return new Promise<PaginatedResponse<User>>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/users/`,
        params
      }).then((res) => {
        resolve(res.data as PaginatedResponse<User>);
      }).catch(() => {
        reject([])
      }).finally(() => {
        setAppLoading(false);
      });
    });
  }

  /*******************/
  /** Get User by ID */
  const getUser = (id: string) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/users/${id}`,
      }).then((res) => {
        const collaborator = res.data as User;
        resolve(collaborator);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }


  /**********************************/
  /** Delete an existing User by ID */
  const deleteUser = (id: number | string) => {
    return new Promise<null>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/users/${id}`,
        method: "delete"
      }).then(() => {
        resolve(null);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /**************************************/
  /** Set Password if recently verified */
  const setPassword = (data: any) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v1/users/set-password/`,
        method: "post",
        data
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*****************/
  /** Archive User */
  const archiveUser = (userId: string) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/users/${userId}/archive`
      }).then((res) => {
        console.log(res.data)
        setAppModal({
          title: "User Archived",
          children: res.data.details.toString() as string,
        });
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }

  /*****************/
  /** Unarchive User */
  const unArchiveUser = (userId: string) => {
    return new Promise<User>((resolve, reject) => {
      setAppLoading(true);
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/users/${userId}/unarchive`
      }).then((res) => {
        setAppModal({
          title: "User Unarchived",
          children: res.data.details.toString() as string
        });
        resolve(res.data);
      }).catch((error) => {
        reject(error);
      }).finally(() => {
        setAppLoading(false);
      })
    });
  }


  return {
    signIn,
    getMe,
    updateUser,
    listUsers,
    getUser,
    deleteUser,
    setPassword,
    archiveUser,
    unArchiveUser
  }
}

export default useUsers;