import { FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import users_icon from "../../../assets/vector-images/modal/users.svg";
import { AuthRouteContext } from "../../../components/auth/AuthRoute";
import { Notification, PageHeader } from "../../../components/layout";
import done_icon from "../../../assets/vector-images/modal/done.svg";
import { FormField, FormValues } from "../../../components/forms/Form/types";
import useCollaborators from "../../../requests/collaborators";
import { User } from "../../../requests/users/types";
import { UserTypes } from "../../../constants/auth";
import Form from "../../../components/forms/Form";
import { URLParams } from "../../../constants";
import useUsers from "../../../requests/users";
import Styles from "./styles";
import { rootPaths } from "../../../routes/paths";



const CreateEdit = () => {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { collaboratorId } = useParams<URLParams>();
  const { user: loggedInUser } = useOutletContext<AuthRouteContext>();
  const { createCollaborator, updateCollaborator } = useCollaborators();
  const { setAppModal, closeModal } = useAppModal();
  const { getUser } = useUsers();
  const [formValues, setFormValues] = useState<FormValues>({});


  /******************************************************/
  /** Save new Collaborator or update existing details */
  const saveDetails = (e: FormEvent | MouseEvent) => {
    e.preventDefault();
    const data = {
      ...formValues
    }
    if (!collaboratorId) {
      // Create Collaborator
      setAppModal({
        title: "You're About to Invite Collaborator",
        icon: users_icon,
        children: (
          `When this Collaborator is invited, they would be ${""
          }able to access the SONA Dashboard based on permissions.
            Would you like to proceed?`
        ),
        controls: (
          <div className="modal-controls">
            <button
              className="button white-black"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                createCollaborator(data)
                  .then(() => {
                    toast(
                      <Notification type="success">
                        Collaborator has been successfully invited.{" "}
                        Please ask them to check their email for login details.
                      </Notification>
                    );
                    exitPage();
                  }).catch(() => null);
              }}
            >
              Okay
            </button>
          </div>
        )
      });
    } else {
      // Update Collaborator
      updateCollaborator(collaboratorId, data)
        .then(() => {
          setAppModal({
            title: "Collaborator Profile Updated",
            icon: done_icon,
            children: (
              `Collaborator profile has been successfully updated ${""
              }and will reflect on their account profile.`
            ),
            cancelCallback: exitPage
          });
        }).catch(() => null);
    }
  }

  const exitPage = async (e?: React.MouseEvent) => {
    if (e) e.preventDefault();
    closeModal();
    navigate(rootPaths.COLLABORATORS);
  }


  // Form Fields
  const formFields: FormField[] = [
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      groupClass: "field-row 1"
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      groupClass: "field-row 1"
    },
    {
      type: "email",
      name: "username",
      label: "Email",
      groupClass: "field-group 1"
    },
    {
      type: "select",
      name: "userType",
      label: "Permissions",
      placeholder: "Choose a Permission",
      disabled: loggedInUser.userType !== "admin",
      options: UserTypes,
      groupClass: "field-group 1"
    },
  ]


  useEffect(() => {
    if (!collaboratorId) return;
    getUser(collaboratorId)
      .then((resData) => {
        const user = resData as User;
        const { firstName, lastName, username, userType } = user;
        setFormValues({
          firstName,
          lastName,
          username,
          userType
        });
      }).catch(() => null);
  }, [collaboratorId])



  return (
    <Styles className="root-content">
      <PageHeader
        breadcrumbs
        title={collaboratorId
          ? "Update Collaborator Details"
          : "Invite"
        }
      />
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={saveDetails}
          ref={formRef}
        >
          <div className="flex align-center justify-between">
            <button
              className="button auto-width white-black"
              onClick={exitPage}
            >
              Cancel
            </button>
            <button
              className="button auto-width"
              type="submit"
            >
              {collaboratorId ? "Update" : "Invite"}
            </button>
          </div>
        </Form>
      </div>
    </Styles>
  );
}

export default CreateEdit;