import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TableColumn } from "../../../../components/layout/Table/types";
import { accountPaths } from "../../../../routes/accounts/paths";
import { Clan } from "../../../../requests/clans/types";
import { Table } from "../../../../components/layout";
import useClans from "../../../../requests/clans";



function ClanList() {
  const { listClans } = useClans();
  const [tableData, setTableData] = useState<Clan[]>([]);


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      className: "al-left",
      content: (clan: Clan) => (
        <Link
          to={accountPaths.clanDetail(clan.id)}
          className="table-title"
        >
          {clan.crest ? (
            <img
              alt=""
              src={clan.crest.url}
              className="crest"
            />
          ) : null}
          <div className="flex column">
            <div className="name">
              {clan.name}
            </div>
          </div>
        </Link>
      )
    }
  ]


  useEffect(() => {
    listClans().then((resData) => {
      setTableData(resData);
    })
  }, [])



  return (
    <div className="page-wrapper">
      <Table
        columns={columns}
        results={tableData}
      />
    </div>
  );
}

export default ClanList;