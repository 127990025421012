import styled from "styled-components";

import arr_right_grey from
  "../../../assets/vector-images/generic/arr-right-grey.svg";
import { colors } from "../../../styles";



const icon_width = "7px";

const Styles = styled.div`
  display: flex;
  .crumb {
    font-weight: 600;
    font-size: 15px;
    padding-right: calc(1rem + ${icon_width});
    margin-right: 1rem;
    position: relative;
    color: ${colors.grey6};
    text-transform: capitalize;
    &::after {
      content: "";
      width: ${icon_width};
      height: 11px;
      background-image: url(${arr_right_grey});
      background-size: cover;
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &.current {
      color: ${colors.black};
      &::after {
        display: none;
      }
    }
  }
`;

export default Styles;