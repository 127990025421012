import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";

import { FormField, FormValues } from "../../../components/forms/Form/types";
import logo from "../../../assets/vector-images/sona/logo.svg";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { SignInData } from "../../../requests/users/types";
import { rootPaths } from "../../../routes/paths";
import { Form } from "../../../components/forms";
import useUsers from "../../../requests/users";



function SignIn() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { signIn } = useUsers();
  const formRef = useRef<HTMLFormElement>(null);
  const [formValues, setFormValues] = useState<FormValues>({});


  /****************/
  /** Submit Data */
  const submitData = () => {
    const data = formValues as SignInData;
    signIn(data)
      .then(() => {
        if (state && state.previousPath)
          navigate(state.previousPath);
        else
          navigate(rootPaths.HOMEPAGE);
      })
      .catch(() => null);
  }

  /****************/
  /** Form Fields */
  const formFields: FormField[] = [
    {
      type: "email",
      name: "username",
      label: "Email",
      placeholder: "you@example.com",
      autoComplete: "email",
      groupClass: "field-group"
    },
    {
      type: "password",
      name: "password",
      label: "Password",
      autoComplete: "current-password",
      placeholder: "Your secure password",
      groupClass: "field-group"
    }
  ]



  return (
    <div className="root-content">
      {/* Header */}
      <header className="root-header">
        <Link to={rootPaths.HOMEPAGE} className="home-link-icon">
          <img src={logo} alt="" />
        </Link>
        <div className="description">
          The ancestors have called you to turn the<br />
          spiritual desert back into a forest
        </div>
      </header>

      {/* Main Content */}
      <div className="page-wrapper">
        <Form
          fields={formFields}
          values={formValues}
          setValues={setFormValues}
          onSubmit={submitData}
          ref={formRef}
        >
          <button
            className="button submit"
            type="submit"
          >
            Sign In
          </button>
        </Form>
      </div>

      {/* Footer */}
      <footer className="root-footer">
        <div className="flex">
          <div className="text">
            Forgot Password?
          </div>
          <Link
            to={nonAuthPaths.FORGOT_PASSWORD}
            className="link"
          >
            Reset here
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default SignIn;