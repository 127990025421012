import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { PageRoute } from "../../components/layout/PageHeader";
import { assetPaths } from "../../routes/assets/paths";
import { PageHeader } from "../../components/layout";



const Assets = () => {
  const { user } = useOutletContext<AuthRouteContext>();

  const pageRoutes: PageRoute[] = [
    {
      name: "Trees",
      path: assetPaths.TREES,
    },
    {
      name: "Avatars",
      path: assetPaths.AVATARS,
    },
    {
      name: "Crests",
      path: assetPaths.CRESTS,
    }
  ]



  return (
    <div className="root-content">
      <PageHeader
        title="Assets"
        pageRoutes={pageRoutes}
      />
      <Outlet context={{ user }} />
    </div>
  );
}

export default Assets;