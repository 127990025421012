import axios from "axios";

import { Family } from "../families/types";



const useClans = () => {
  const listClans = () => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/clans/`,
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error)
      });
    });
  }

  const getClan = (clanId: string) => {
    return new Promise<any>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/clans/${clanId}`,
      }).then((res) => {
        resolve(res.data);
      }).catch((error) => {
        reject(error)
      });
    });
  }

  const listFamiliesInClan = (clanId: string) => {
    return new Promise<Family<null>[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/clans/${clanId}/families`,
      }).then((res) => {
        resolve(res.data.results as Family<null>[]);
      }).catch((error) => {
        reject(error)
      });
    });
  }


  return {
    listClans,
    getClan,
    listFamiliesInClan
  }
}

export default useClans;