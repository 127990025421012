import styled from "styled-components";
import {
  colors, functions, properties, resolutions, snippets
} from "../../../styles";
import arr_right_grey from "../../../assets/vector-images/generic/arr-right-grey.svg";
import search_icon from "../../../assets/vector-images/generic/search.svg";



const icon_width = "7px";


const Styles = styled.header`
  gap: 12px;
  .primary, .secondary {
    width: 100%;
  }

  ${'' /* Primary Row */}
  .primary {
    border-bottom: 1px solid ${functions.rgba(colors.black, 0.1)};
    gap: 16px;
    @media(max-width: ${resolutions.tabletLarge}) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
    .title,
    .tab-link,
    .search-form .text-field {
      padding: 0.5rem 0;
    }
    .title {
      font-size: 1rem;
      min-width: 100px;
      display: block;
    }
    .tab-links {
      display: flex;
      align-items: center;
      gap: 2rem;
      @media(max-width: ${resolutions.tabletLarge}) {
        margin-left: 0;
      }
      .tab-link {
        display: block;
        position: relative;
        color: ${colors.grey6};
        transition: all ${properties.transition};
        .count {
          padding: 3px 5px;
          border-radius: 5px;
          background-color: ${functions.rgba(colors.primary, 0.16)};
          color: ${colors.primary};
          font-size: 10px;
          font-weight: 600;
        }
        &.active {
          color: ${colors.primary};
          &::after {
            background-color: ${colors.primary};
          }
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
        }
      }
    }
    .breadcrumbs {
      display: flex;
      .page {
        font-weight: 600;
        font-size: 15px;
        padding-right: calc(1rem + ${icon_width});
        margin-right: 1rem;
        position: relative;
        color: ${colors.grey6};
        text-transform: capitalize;
        &::after {
          content: "";
          width: ${icon_width};
          height: 11px;
          background-image: url(${arr_right_grey});
          background-size: cover;
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        &.current {
          color: ${colors.black};
          &::after {
            display: none;
          }
        }
      }
    }
    .search-form {
      margin-left: auto;
      max-width: 200px;
      min-width: auto;
      @media(max-width: ${resolutions.tabletLarge}) {
        margin-left: 0;
        margin-top: 1rem;
      }
      .text-field {
        width: fit-content;
        border: none;
        background-color: transparent;
        position: relative;
        margin-left: 1.5rem;
        &::before {
          left: -1.5rem;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          ${snippets.pseudoImg}
          background-image: url(${search_icon});
        }
        input {
          font-size: 12px;
          padding: 0;
          &::placeholder {
            color: ${colors.grey9};
            font-weight: 600;
          }
        }
      }
    }
  }

  ${'' /* Secondary Row (Children) */}
  .secondary {
    width: 100%;
    .select-field {
      width: fit-content;
      border: none;
      background-color: transparent;
      padding: 0;
      select {
        padding: 0;
      }
    }
  }

  &.username-link {
    .breadcrumbs .page {
      &:last-child {
        text-transform: unset !important;
      }
    }
  }
`;

export default Styles;