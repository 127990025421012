import { ReactNode } from "react";

import Styles from "./styles";



type Props = {
  message?: ReactNode
}

function Loader({ message }: Props) {
  return (
    <Styles className="loader">
      <div className="spinner">
        <div />
      </div>
      {message
        ?
        <div className="load-message">
          {message}
        </div>
        : null
      }
    </Styles>
  );
}

export default Loader;