import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

import { User } from "../../../requests/users/types";
import { Desert } from "../../../types/core";
import * as actions from "./types";



const useAppActions = () => {
  const dispatch: Dispatch<actions.Action> = useDispatch();

  /* AUTH */
  const saveToken = (token: string) =>
    dispatch(actions.saveToken(token));
  const saveUser = (user: User) =>
    dispatch(actions.saveUser(user));
  const logoutUser = () =>
    dispatch(actions.logoutUser());

  /* UI */
  const setAppLoading = (value: boolean) =>
    dispatch(actions.setAppLoading(value));

  /* MISSIONS */
  const setDeserts = (payload: Desert[]) =>
    dispatch(actions.setDeserts(payload));

  /* MISCELLANY */
  const setNextPath = (path: string) =>
    dispatch(actions.setNextPath(path));


  return {
    saveToken,
    saveUser,
    logoutUser,
    setAppLoading,
    setDeserts,
    setNextPath
  }
}

export default useAppActions;
