import styled from "styled-components";

import { colors, functions, properties, snippets } from "../../../styles";



const Styles = styled.div`
  z-index: ${properties.highestZIndex};
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${functions.rgba(colors.black, 0.8)};
  ${snippets.flexCenter}
  ${snippets.absoluteCenter};
  text-align: center;
  .spinner {
    margin: 0;
    padding: 1rem;
    margin: 2rem;
    div {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid ${colors.primary};
      width: 3rem;
      height: 3rem;
      margin: 0 auto;
      -webkit-animation: spin 1s linear infinite; /* Safari */
      animation: spin 500ms linear infinite;
    
      @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
    
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
  .load-message {
    font-size: 2.5rem;
    color: ${colors.primary};
    font-weight: 400;
    -webkit-text-stroke: 1px ${functions.rgba(colors.white, 0.4)};
  }
`;

export default Styles;
