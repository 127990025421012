export const affirmationsRoot = "/affirmations";
export const affirmationId = ":affirmationId";


export const affirmationPaths = {
  PUBLISHED: `${affirmationsRoot}/published`,
  DRAFT: `${affirmationsRoot}/draft`,
  CREATE: `${affirmationsRoot}/create`,
  EDIT: `${affirmationsRoot}/${affirmationId}`,
  edit: (id: number | string) => `${affirmationsRoot}/${id}`
}