import axios from "axios";

import { ErrorResponse } from "../../types";
import { Tribe } from "./types";



/*  ########################### */
/** ## TRIBE NETWORK REQUESTS # */
/*  ########################### */
const useTribe = () => {
  const listTribeAccounts = (params: Record<string, string>) => {
    return new Promise<Tribe[]>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_PAYMENTS_API_URL
          }/v2/tribe/accounts/`,
        params
      }).then((res) => {
        const resData = res.data.results as Tribe[];
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    listTribeAccounts
  }
}

export default useTribe;