import {
  ReactComponent as DashboardIcon
} from "../../../assets/vector-images/side-menu/00.svg";
import {
  ReactComponent as AccountsIcon
} from "../../../assets/vector-images/side-menu/01.svg";
import {
  ReactComponent as MissionsIcon
} from "../../../assets/vector-images/side-menu/02.svg";
import {
  ReactComponent as ProfileIcon
} from "../../../assets/vector-images/side-menu/07.svg";
import {
  ReactComponent as WalletsIcon
} from "../../../assets/vector-images/side-menu/04.svg";
import {
  ReactComponent as AffirmIcon
} from "../../../assets/vector-images/side-menu/03.svg";
import {
  ReactComponent as OffersIcon
} from "../../../assets/vector-images/side-menu/05.svg";
import {
  ReactComponent as CollabIcon
} from "../../../assets/vector-images/side-menu/06.svg";
import { rootPaths } from "../../../routes/paths";



type NvLnk = {
  name: string;
  path: string;
  icon: React.ReactElement;
}

export const navLinks: NvLnk[] = [
  {
    name: "Dashboard",
    path: rootPaths.DASHBOARD,
    icon: <DashboardIcon />
  },
  {
    name: "Accounts",
    path: rootPaths.ACCOUNTS,
    icon: <AccountsIcon />
  },
  {
    name: "Assets",
    path: rootPaths.ASSETS,
    icon: <AccountsIcon />
  },
  {
    name: "Missions",
    path: rootPaths.MISSIONS,
    icon: <MissionsIcon />
  },
  {
    name: "Affirmations",
    path: rootPaths.AFFIRMATIONS,
    icon: <AffirmIcon />
  },
  {
    name: "Billing",
    path: rootPaths.BILLING,
    icon: <WalletsIcon />
  },
  {
    name: "Emails",
    path: rootPaths.EMAILS,
    icon: <WalletsIcon />
  },
  {
    name: "Offers",
    path: rootPaths.OFFERS,
    icon: <OffersIcon />
  },
  {
    name: "Collaborators",
    path: rootPaths.COLLABORATORS,
    icon: <CollabIcon />
  },
  {
    name: "Profile",
    path: rootPaths.PROFILE,
    icon: <ProfileIcon />
  }
]
