import { FormField } from "../../../../components/forms/Form/types"



/**********************/
/** Coordinate Fields */
export const coordinates: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "coordinates",
    reactNode: (
      <legend>
        Tree Coordinates
      </legend>
    )
  },
  {
    type: "text",
    name: "lat",
    label: "Latitude",
    placeholder: "Latitude",
    groupClass: "coordinates",
  },
  {
    type: "text",
    name: "lng",
    label: "Longitude",
    placeholder: "Longitude",
    groupClass: "coordinates",
  }
]

/******************/
/** Barren Fields */
export const barren: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "barren",
    reactNode: (
      <legend>
        Barren
      </legend>
    )
  },
  {
    type: "text",
    name: "barrenUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "barren",
  }
]

/****************/
/** Seed Fields */
export const seed: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "seed",
    reactNode: (
      <legend>
        Seed
      </legend>
    )
  },
  {
    type: "text",
    name: "seedGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "seed",
  },
  {
    type: "text",
    name: "seedUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "seed",
  }
]

/******************/
/** Sprout Fields */
export const sprout: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "sprout",
    reactNode: (
      <legend>
        Sprout
      </legend>
    )
  },
  {
    type: "text",
    name: "sproutGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "sprout",
  },
  {
    type: "text",
    name: "sproutUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "sprout",
  }
]

/***************************/
/** Sprout Watering Fields */
export const sproutWatering: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "sprout-watering",
    reactNode: (
      <legend>
        Sprout Watering
      </legend>
    )
  },
  {
    type: "text",
    name: "sproutWateringGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "sprout-watering",
  },
  {
    type: "text",
    name: "sproutWateringUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "sprout-watering",
  }
]

/********************/
/** Seedling Fields */
export const seedling: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "seedling",
    reactNode: (
      <legend>
        Seedling
      </legend>
    )
  },
  {
    type: "text",
    name: "seedlingGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "seedling",
  },
  {
    type: "text",
    name: "seedlingUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "seedling",
  }
]

/*******************************/
/** Seedling Watering 1 Fields */
export const seedlingWatering1: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "seedling-watering-1",
    reactNode: (
      <legend>
        Seedling Watering 1
      </legend>
    )
  },
  {
    type: "text",
    name: "seedlingWatering1Url",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "seedling-watering-1",
  }
]

/*******************************/
/** Seedling Watering 2 Fields */
export const seedlingWatering2: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "seedling-watering-2",
    reactNode: (
      <legend>
        Seedling Watering 2
      </legend>
    )
  },
  {
    type: "text",
    name: "seedlingWatering2GrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "seedling-watering-2",
  },
  {
    type: "text",
    name: "seedlingWatering2Url",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "seedling-watering-2",
  }
]

/*******************/
/** Sapling Fields */
export const sapling: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "sapling",
    reactNode: (
      <legend>
        Sapling
      </legend>
    )
  },
  {
    type: "text",
    name: "saplingGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "sapling",
  },
  {
    type: "text",
    name: "saplingUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "sapling",
  }
]

/******************************/
/** Sapling Watering 1 Fields */
export const saplingWatering1: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "sapling-watering-1",
    reactNode: (
      <legend>
        Sapling Watering 1
      </legend>
    )
  },
  {
    type: "text",
    name: "saplingWatering1Url",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "sapling-watering-1",
  }
]


/******************************/
/** Sapling Watering 2 Fields */
export const saplingWatering2: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "sapling-watering-2",
    reactNode: (
      <legend>
        Sapling Watering 2
      </legend>
    )
  },
  {
    type: "text",
    name: "saplingWatering2GrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "sapling-watering-2",
  },
  {
    type: "text",
    name: "saplingWatering2Url",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "sapling-watering-2",
  }
]

/**********************/
/** Young Tree Fields */
export const youngTree: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "young-tree",
    reactNode: (
      <legend>
        Young Tree
      </legend>
    )
  },
  {
    type: "text",
    name: "youngTreeGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "young-tree",
  },
  {
    type: "text",
    name: "youngTreeUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "young-tree",
  }
]

/*********************************/
/** Young Tree Watering 1 Fields */
export const youngTreeWatering1: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "young-tree-watering-1",
    reactNode: (
      <legend>
        Young Tree Watering 1
      </legend>
    )
  },
  {
    type: "text",
    name: "youngTreeWatering1Url",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "young-tree-watering-1",
  }
]

/*********************************/
/** Young Tree Watering 2 Fields */
export const youngTreeWatering2: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "young-tree-watering-2",
    reactNode: (
      <legend>
        Young Tree Watering 2
      </legend>
    )
  },
  {
    type: "text",
    name: "youngTreeWatering2GrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "young-tree-watering-2",
  },
  {
    type: "text",
    name: "youngTreeWatering2Url",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "young-tree-watering-2",
  }
]

/**************************/
/** Tree Completed Fields */
export const treeCompleted: FormField[] = [
  {
    type: "react-node",
    name: "",
    label: "",
    groupClass: "tree-completed",
    reactNode: (
      <legend>
        Tree Completed
      </legend>
    )
  },
  {
    type: "text",
    name: "treeCompletedGrowthUrl",
    label: "Growth URL",
    placeholder: "Enter the Video URL",
    groupClass: "tree-completed",
  },
  {
    type: "text",
    name: "treeCompletedUrl",
    label: "State URL",
    placeholder: "Enter the Video URL",
    groupClass: "tree-completed",
  }
]