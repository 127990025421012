import styled from "styled-components";

import { colors, functions, snippets } from "../../../styles";



const Styles = styled.div`
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  .modal-overlay {
    background-color: ${functions.rgba(colors.black, 0.8)};
    width: 100%;
    height: 100%;
    ${snippets.flexCenter}
    .modal-container {
      background-color: ${colors.white};
      width: 420px;
      margin: 0;
      border-radius: 12px;
      padding: 24px;
      box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
      &>* {
        width: 100%;
      }
      .modal-header {
        .head-img {
          width: 48px;
          height: 48px;
        }
        .close-icon {
          width: 24px;
          height: 24px;
        }
        .head-title {
          font-size: 15px;
          margin-top: 16px;
          margin-bottom: 8px;
        }
      }
      .modal-content {
        font-size: 14px;
      }
      .modal-controls {
        display: flex;
        margin-top: 32px;
        gap: 12px;
      }
    }
  }
`;

export default Styles;