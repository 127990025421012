import { accountPaths } from "./paths";
import { RouteType } from "../types";
import {
  UserList, UserDetail,
  FamilyList, FamilyDetail,
  ClanList, ClanDetail,
  KycList, KycDetail
} from "../../pages/Accounts/pages";



/******************/
/* Account Routes */
export const accountRoutes: RouteType[] = [
  {
    path: accountPaths.USERS,
    element: <UserList />,
  },
  {
    path: accountPaths.FAMILIES,
    element: <FamilyList />
  },
  {
    path: accountPaths.CLANS,
    element: <ClanList />
  },
  {
    path: accountPaths.KYC,
    element: <KycList />
  }
]


/*********/
/* Users */
export const accountsUserRoutes: RouteType[] = [
  {
    path: accountPaths.USER_DETAIL,
    element: <UserDetail />
  }
]


/************/
/* Families */
export const accountsFamilyRoutes: RouteType[] = [
  {
    path: accountPaths.FAMILY_DETAIL,
    element: <FamilyDetail />
  }
]


/*********/
/* Clans */
export const accountsClanRoutes: RouteType[] = [
  {
    path: accountPaths.CLAN_DETAIL,
    element: <ClanDetail />
  }
]


/*******/
/* Kyc */
export const accountsKycRoutes: RouteType[] = [
  {
    path: accountPaths.KYC_DETAIL,
    element: <KycDetail />
  }
]