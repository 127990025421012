import { Navigate } from "react-router-dom";

import { nonAuthPaths } from "../../routes/nonAuth/paths";
import { useAppState } from "../../redux/state";
import { rootPaths } from "../../routes/paths";



const Home = () => {
  const { token } = useAppState();
  return (
    <Navigate
      replace
      to={token
        ? rootPaths.ACCOUNTS
        : nonAuthPaths.SIGN_IN
      }
    />
  );
}

export default Home;