import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { ModalProvider } from "../components/layout/AppModal/utils";
import { ScrollToTop } from "../components/layout";
import { store, persistor } from "../redux/store";
import GlobalStyles from "../styles/GlobalStyles";
import { mapRoutes } from "./utils";
// Non-Auth
import { nonAuthRoutes } from "../routes/nonAuth";
import { AuthRoute, NonAuthRoute } from "../components/auth";
// Accounts

import {
  accountRoutes, accountsClanRoutes, accountsFamilyRoutes,
  accountsKycRoutes, accountsUserRoutes
} from "../routes/accounts";
import {
  Accounts, Clans, Families, Kyc, Users
} from "../pages/Accounts/pages";
// Affirmations
import {
  affirmationRoutes
} from "../routes/affirmations";
import {
  Affirmations
} from "../pages/Affirmations/pages";
//  Assets
import {
  assetCrestRoutes, assetRoutes, assetTreeRoutes
} from "../routes/assets";
import {
  Assets, Crests, Trees
} from "../pages/Assets/pages";
// Billing
import {
  billingRoutes, discountRoutes, planRoutes,
  subscriptionRoutes
} from "../routes/billing";
import {
  Billing, Plans, Discounts, Subscriptions
} from "../pages/Billing/pages"
// Emails
import {
  emailRoutes
} from "../routes/emails";
import {
  Emails
} from "../pages/Emails/pages";
// Missions
import { missionRoutes } from "../routes/missions";
import { Missions } from "../pages/Missions/pages";
// Offers
import { offerRoutes } from "../routes/offers";
import { Offers } from "../pages/Offers/pages";
// Collaborators
import { collabRoutes } from "../routes/collaborators";
import { Collaborators } from "../pages/Collaborators/pages";
// Profile
import { profileRoutes } from "../routes/profile";
import { Profile } from "../pages/Profile/pages";
// Auth
import { rootPaths } from "../routes/paths";
import { rootRoutes } from "../routes";
import Page404 from "../pages/Page404";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import { dashboardRoutes } from "../routes/dashboard";




function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ModalProvider>
          <Router>
            <ScrollToTop />
            <GlobalStyles />
            <Routes>
              <Route path={rootPaths.HOMEPAGE} element={<Home />} />
              {/*######################*/}
              {/* AUTHENTICATED ROUTES */}
              <Route element={<AuthRoute />}>
                {mapRoutes(rootRoutes)}

                {/************/}
                {/* Dashboard */}
                <Route element={<Dashboard />}>
                  {mapRoutes(dashboardRoutes)}
                </Route>

                {/************/}
                {/* ACCOUNTS */}
                <Route element={<Accounts />}>
                  {mapRoutes(accountRoutes)}
                </Route>
                <Route element={<Users />}>
                  {mapRoutes(accountsUserRoutes)}
                </Route>
                <Route element={<Families />}>
                  {mapRoutes(accountsFamilyRoutes)}
                </Route>
                <Route element={<Clans />}>
                  {mapRoutes(accountsClanRoutes)}
                </Route>
                <Route element={<Kyc />}>
                  {mapRoutes(accountsKycRoutes)}
                </Route>

                {/****************/}
                {/* AFFIRMATIONS */}
                <Route element={<Affirmations />}>
                  {mapRoutes(affirmationRoutes)}
                </Route>

                {/**********/}
                {/* ASSETS */}
                <Route element={<Assets />}>
                  {mapRoutes(assetRoutes)}
                </Route>
                <Route element={<Trees />}>
                  {mapRoutes(assetTreeRoutes)}
                </Route>
                <Route element={<Crests />}>
                  {mapRoutes(assetCrestRoutes)}
                </Route>

                {/***********/}
                {/* BILLING */}
                <Route element={<Billing />}>
                  {mapRoutes(billingRoutes)}
                </Route>
                <Route element={<Plans />}>
                  {mapRoutes(planRoutes)}
                </Route>
                <Route element={<Discounts />}>
                  {mapRoutes(discountRoutes)}
                </Route>
                <Route element={<Subscriptions />}>
                  {mapRoutes(subscriptionRoutes)}
                </Route>

                {/*****************/}
                {/* COLLABORATORS */}
                <Route element={<Collaborators />}>
                  {mapRoutes(collabRoutes)}
                </Route>

                {/**********/}
                {/* EMAILS */}
                <Route element={<Emails />}>
                  {mapRoutes(emailRoutes)}
                </Route>

                {/************/}
                {/* MISSIONS */}
                <Route element={<Missions />}>
                  {mapRoutes(missionRoutes)}
                </Route>

                {/**********/}
                {/* OFFERS */}
                <Route element={<Offers />}>
                  {mapRoutes(offerRoutes)}
                </Route>

                {/***********/}
                {/* PROFILE */}
                <Route element={<Profile />}>
                  {mapRoutes(profileRoutes)}
                </Route>
              </Route>

              {/*##########################*/}
              {/* NON-AUTHENTICATED ROUTES */}
              <Route element={<NonAuthRoute />}>
                {mapRoutes(nonAuthRoutes)}
              </Route>

              {/* Page Not Found */}
              <Route path="*" element={<Page404 />} />
            </Routes>
          </Router>
        </ModalProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;