import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TableColumn } from "../../../../components/layout/Table/types";
import { accountPaths } from "../../../../routes/accounts/paths";
import { Family } from "../../../../requests/families/types";
import useFamilies from "../../../../requests/families";
import { Table } from "../../../../components/layout";



function FamilyList() {
  const [tableData, setTableData] = useState<Family<undefined>[]>([]);
  const { listFamilies } = useFamilies();


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      content: (family: Family<null>) => (
        <Link
          to={accountPaths.familyDetail(family.id)}
          className="table-title"
        >
          {family.name ? (
            <>
              {family.crest ? (
                <img
                  src={family.crest.url}
                  alt=""
                  className="crest"
                />
              ) : null}
              <div>{family.name}</div>
            </>
          ) : (
            <div>{family.id}</div>
          )}
        </Link >
      )
    },
    {
      title: "Members",
      key: "members",
      content: (family: Family<null>) => (
        family.userCount
      )
    }
  ]

  /*****************/
  /** Get all data */
  const syncData = (e?: MouseEvent) => {
    if (e) e.preventDefault();
    listFamilies().then((resData) => {
      setTableData(resData);
    })
  }


  useEffect(() => {
    syncData();
  }, [])



  return (
    <div className="page-wrapper">
      <Table
        columns={columns}
        results={tableData}
      />
    </div>
  );
}

export default FamilyList;