import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { Subscription } from "../../../../requests/subscriptions/types";
import useSubscriptions from "../../../../requests/subscriptions";
import { Card, PageHeader } from "../../../../components/layout";
import { usePageControls } from "../../../../utils/components";
import { Family } from "../../../../requests/families/types";
import { Tribe } from "../../../../requests/tribe/types";
import useFamilies from "../../../../requests/families";
import { User } from "../../../../requests/users/types";
import useUsers from "../../../../requests/users";
import { URLParams } from "../../../../constants";
import useTribe from "../../../../requests/tribe";
import AccessManagement from "./accessManagement";
import SubscriptionData from "./subscriptions";
import FamilyData from "./family";
import TribeData from "./tribe";
import UserData from "./user";
import Styles from "./styles";
import useAddresses from "../../../../requests/addresses";
import { Address } from "../../../../requests/addresses/types";
import AddressData from "./address";



function UserDetail() {
  const { userId } = useParams<URLParams>();
  const { previousPage } = usePageControls();
  const { getUser } = useUsers();
  const { getFamily } = useFamilies();
  const { listTribeAccounts } = useTribe();
  const { listSubscriptions } = useSubscriptions();
  const { getUsersAddress } = useAddresses();
  const [user, setUser] = useState<User | null>(null);
  const [address, setAddress] = useState<Address | null>(null);
  const [tribe, setTribe] = useState<Tribe | null>(null);
  const [family, setFamily] = useState<Family<undefined> | null>(null);
  const [subscription, setSubscription] = useState<
    Subscription | null
  >(null);


  useEffect(() => {
    if (!userId) {
      previousPage();
      return;
    };
    // Get User Data
    getUser(userId)
      .then((user) => {
        setUser(user);
        // Family data
        if (user.familyId) {
          getFamily(user.familyId)
            .then((family) => {
              setFamily(family);
            });
          // Subscription
          listSubscriptions({ familyId: user.familyId })
            .then((subscriptions) => {
              setSubscription(subscriptions[0]);
            });
        }
        // Tribe Account
        listTribeAccounts({ userId })
          .then((tribeAccounts) => {
            setTribe(tribeAccounts[0]);
          });
        getUsersAddress(userId)
          .then((resData) => {
            setAddress(resData)
          }).catch(() => null);

      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])



  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        breadcrumbs
        className="username-link"
        title={user
          ? user.username.toLowerCase()
          : "User's Details"
        }
      />

      {/* Main Content */}
      <div className="page-wrapper">
        <div className="grid">
          <UserData user={user} />
          {address ? (
            <AddressData address={address} />
          ) : null}
          <Card>
            <FamilyData family={family} />
            <SubscriptionData subscription={subscription} />
          </Card>
          <AccessManagement user={user} />
          <TribeData tribe={tribe} />
        </div>
      </div>
    </Styles>
  );
}

export default UserDetail