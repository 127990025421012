import styled from "styled-components";

import { colors } from "../../../styles";



const Styles = styled.div`
  flex: 1;
  padding: 24px 20px;
  background-color: ${colors.white};
  border: 2px solid ${colors.grey7};
  border-radius: 10px;
  
  .card-title {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    color: ${colors.grey3};
    &.underline {
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-bottom: 1px solid ${colors.grey8};
    }
  }
  .main-value {
    font-weight: 700;
    font-size: 20px;
  }
  .value {
    font-weight: 700;
    &.main {
      font-size: 20px;
    }
    &.sub {
      font-size: 12px;
      line-height: 20px
    }
  }
  .chart {
    margin-top: 2rem;
    &.mini {
      margin-top: 1rem;
      canvas {
        max-height: 100px;
      }
      &.donut {
        max-height: 80px;
        margin: 0;
      }
      &.line {
        width: 100px;
      }
    }
  }
  .mid-chart {
    width: 100px;
  }
  canvas {
    max-height: 250px;
  }
`;

export default Styles;