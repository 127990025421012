import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { PageBreadcrumbs } from "../../components/layout";



const Dashboard = () => {
  const { user } = useOutletContext<AuthRouteContext>();

  return (
    <div className="root-content">
      <PageBreadcrumbs />
      <Outlet context={{ user }} />
    </div>
  );
}

export default Dashboard;