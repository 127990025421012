import { billingPaths } from "./paths";
import { RouteType } from "../types";
import {
  CreateEditDiscount,
  DiscountDetail,
  DiscountList,
  EditPlan,
  PlanDetail,
  PlanList,
  SubscriptionList
} from "../../pages/Billing/pages";



/*******************/
/** Billing Routes */
export const billingRoutes: RouteType[] = [
  {
    path: billingPaths.LIST_PLANS,
    element: <PlanList />
  },
  {
    path: billingPaths.LIST_DISCOUNTS,
    element: <DiscountList />
  },
  {
    path: billingPaths.LIST_SUBSCRIPTIONS,
    element: <SubscriptionList />
  }
]


/********************/
/** Billing > Plans */
export const planRoutes: RouteType[] = [
  {
    path: billingPaths.PLAN_DETAIL,
    element: <PlanDetail />
  },
  {
    path: billingPaths.EDIT_PLAN,
    element: <EditPlan />
  }
]


/************************/
/** Billing > Discounts */
export const discountRoutes: RouteType[] = [
  {
    path: billingPaths.ADD_DISCOUNT,
    element: <CreateEditDiscount />
  },
  {
    path: billingPaths.DISCOUNT_DETAIL,
    element: <DiscountDetail />
  },
  {
    path: billingPaths.EDIT_DISCOUNT,
    element: <CreateEditDiscount />
  }
]


/****************************/
/** Billing > Subscriptions */
export const subscriptionRoutes: RouteType[] = [
  {
    path: billingPaths.ADD_SUBSCRIPTION,
    element: <></>
  },
  {
    path: billingPaths.SUBSCRIPTION_DETAIL,
    element: <></>
  }
]