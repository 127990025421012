import { Outlet, useOutletContext } from "react-router-dom";

import { AuthRouteContext } from "../../components/auth/AuthRoute";
import { PageRoute } from "../../components/layout/PageHeader";
import { accountPaths } from "../../routes/accounts/paths";
import { PageHeader } from "../../components/layout";



export type AccountsContext = AuthRouteContext;

function Accounts() {
  const { user } = useOutletContext<AccountsContext>();
  const pageRoutes: PageRoute[] = [
    {
      name: "Users",
      path: accountPaths.USERS,
    },
    {
      name: "Families",
      path: accountPaths.FAMILIES,
    },
    {
      name: "Clans",
      path: accountPaths.CLANS,
    }
  ]

  if (user.familyRole === "admin") {
    pageRoutes.push(
      {
        name: "KYC",
        path: accountPaths.KYC,
      }
    );
  }



  return (
    <div className="root-content">
      <PageHeader
        title="Accounts"
        pageRoutes={pageRoutes}
        showSearch={true}
      />
      <Outlet context={{ user }} />
    </div>
  );
}

export default Accounts;