import { Link, useParams } from "react-router-dom";
import moment from "moment";

import { Loader, PageHeader, PrettyJson } from "../../../components/layout";
import { ReactNode, useEffect, useState } from "react";
import { Email } from "../../../requests/emails/types";
import useEmails from "../../../requests/emails";
import { URLParams } from "../../../constants";
import Styles from "./styles";
import { emailPaths } from "../../../routes/emails/paths";
import { accountPaths } from "../../../routes/accounts/paths";



function ViewEmail() {
  const { emailId } = useParams<URLParams>();
  const { getEmail } = useEmails();
  const [email, setEmail] = useState<Email>();


  /**********************/
  /** Get Email Details */
  const retrieveEmail = (id: number | string) => {
    getEmail(id)
      .then((email) => {
        setEmail(email);
      })
      .catch(() => null);
  }


  useEffect(() => {
    if (!emailId) return;
    retrieveEmail(emailId);
  }, [emailId])


  type InfoColumn = {
    title: string;
    content: (model: any) => ReactNode;
  }
  const modelFields: InfoColumn[] = [
    {
      title: "Email",
      content: (email: Email) => (
        <div className="bold">
          {email.email}
        </div>
      )
    },
    {
      title: "Template Name",
      content: (email: Email) => (
        `${email.templateName}`
      )
    },
    {
      title: "Template ID",
      content: (email: Email) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="link underline"
          href={`https://app.mailersend.com/templates/${email.templateId
            }/edit`}
        >
          {email.templateId}
        </a>
      )
    },
    {
      title: "Created At",
      content: (email: Email) => (
        <div className="grey-text">
          {moment(email.createdAt).isValid()
            ? moment(email.createdAt).format("Do MMMM, YYYY - hh:mma")
            : "-"
          }
        </div>
      )
    },
    {
      title: "Created By",
      content: (email: Email) => (
        <div className="grey-text">
          {email.sentBy ? (
            `${email.sentBy.firstName} ${email.sentBy.lastName}`
          ) : "-"}
        </div>
      )
    },
    {
      title: "Status",
      content: (email: Email) => (
        <div className={`status ${email.status}`}>
          {email.status}
        </div>
      )
    },
    {
      title: "Sent At",
      content: (email: Email) => (
        <div className="grey-text">
          {moment(email.sentAt).isValid()
            ? moment(email.sentAt).format("Do MMMM, YYYY - hh:mma")
            : "-"
          }
        </div>
      )
    }
  ]

  // Optional Error Response
  if (
    (email && email.errorResponse) &&
    Object.keys(email.errorResponse).length > 0
  ) {
    modelFields.push(
      {
        title: "Error",
        content: (email: Email) => (
          <PrettyJson
            json={email.errorResponse || ""}
            title="Mailersend Error"
          />
        )
      }
    )
  }

  if (email && email.receivedBy) {
    modelFields.push(
      {
        title: "User",
        content: (email: Email) => (
          <Link
            className="link underline"
            to={accountPaths.userDetail(email.receivedBy?.id || "")}
          >
            {email.receivedBy ? (
              `${email.receivedBy.firstName} ${email.receivedBy.lastName}`
            ) : "-"}
          </Link>

        )
      },
    )
  }


  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader
        breadcrumbs
        title={emailId || ""}
      />

      {/* Main Content */}
      <div className="page-wrapper">
        {!email ? (<Loader />) : (
          <div className="model-detail">
            <ul>
              {modelFields.map((field, index) =>
                <li
                  key={`${field.title}-${index}`}
                  className="model-field flex align-center justify-between"
                >
                  <div className="title">
                    {field.title}
                  </div>
                  <div className="content">
                    {field.content(email)}
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}

        <Link
          className="button auto-width mlr-auto submit"
          to={emailPaths.LIST}
        >
          View All Emails
        </Link>
      </div>
    </Styles>
  );
}

export default ViewEmail;