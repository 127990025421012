import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import user_icon from "../../../../assets/vector-images/generic/user-icon.svg";
import users_icon from "../../../../assets/vector-images/panel/users.svg";
import file_icon from "../../../../assets/vector-images/generic/file.svg";
import { TableColumn } from "../../../../components/layout/Table/types";
import { accountPaths } from "../../../../routes/accounts/paths";
import { StatsCard } from "../../../../components/layout/Card";
import { useQueryParams } from "../../../../utils/components";
import { Card, Table } from "../../../../components/layout";
import { User } from "../../../../requests/users/types";
import { PaginatedResponse } from "../../../../types";
import useUsers from "../../../../requests/users";



function UserList() {
  const { locationSearch, queryParams } = useQueryParams();
  const search = queryParams.get("search") || "";
  const page = queryParams.get("page") || "1";
  const pageSize = queryParams.get("pageSize") || "";
  const { listUsers } = useUsers();
  const [tableData, setTableData] = useState<PaginatedResponse<User>>();
  const [stats, setStats] = useState<StatsCard[]>([]);


  const columns: TableColumn[] = [
    {
      title: "Name",
      key: "name",
      content: (user: User) => (
        <Link
          to={accountPaths.userDetail(user.id)}
          className="table-title"
        >
          <img
            alt=""
            src={(user.avatar?.url)
              ? user.avatar.url
              : user_icon
            }
          />
          <div>{user.firstName} {user.lastName}</div>
          <div className={`status ${user.familyRole}`}>
            {user.familyRole === "admin"
              ? "Parent"
              : "Learner"
            }
          </div>
        </Link>
      )
    },
    {
      title: "Email / Username",
      key: "username",
      className: "al-left"
    },
    {
      title: "Last Active",
      key: "lastActive",
      className: "al-left",
      content: (user: User) => (
        <div className="grey">
          {user.lastLogin ? (
            moment(user.lastLogin).format("Do MMM YYYY, hh:mma")
          ) : "Has not signed-in yet"}
        </div>
      )
    },
    {
      title: "Actions",
      key: "",
      className: "al-right",
      content: (user: User) => (
        <div
          className="flex align-end justify-end"
          style={{ gap: "1.5rem" }}
        >
          <Link to={accountPaths.userDetail(user.id)}>
            <img src={file_icon} alt="" />
          </Link>
        </div>
      )
    }
  ]


  /******************/
  /** Set card data */
  const upsertStat = (stat: StatsCard) => {
    setStats(prevStats => {
      const existingStatIndex = prevStats.findIndex(
        item => item.title === stat.title
      );
      if (existingStatIndex > -1) {
        // Update Stat
        const updatedStats = [...prevStats];
        updatedStats[existingStatIndex] = stat;
        return updatedStats;
      } else {
        // Add New Stat
        return [...prevStats, stat];
      }
    });
  }


  /*****************/
  /** Get all data */
  const syncData = () => {
    const userTypes = ["primary", "secondary", "basic"];
    const params = {
      search,
      userType: userTypes.join(),
      page,
      pageSize
    }
    // Get User List
    listUsers(params)
      .then((data) => {
        setTableData(data);
        upsertStat({
          title: "Total Users",
          content: (
            <div className="value main">
              {data.count}
            </div>
          )
        });
      });
  }


  useEffect(() => {
    syncData();
  }, [locationSearch])



  return (
    <div className="page-wrapper">
      <div className="grid">
        {stats.map((stat, index) =>
          <Card key={index}>
            <div className="card-title">
              {stat.title}
            </div>
            {stat.content}
          </Card>
        )}
      </div>
      <Table
        {...tableData}
        columns={columns}
        noData={{
          icon: users_icon,
          title: "No User Accounts"
        }}
      />
    </div>
  );
}

export default UserList;