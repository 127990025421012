import styled from "styled-components";

import {
  colors, functions, properties, resolutions, snippets
} from "../../../styles";



const Styles = styled.div`
  padding: 36px 24px;
  min-width: ${properties.sidebarWidth};
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media(max-width: ${resolutions.tabletLarge}) {
    display: none;
  }
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: ${resolutions.tabletLarge}) {
      position: absolute;
      height: ${properties.navHeight};
      background-color: ${colors.white};
      padding: 24px;
      width: 100%;
      top: 0;
      left: 100%;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
    }
    #menu {
      width: 1rem;
      height: 1rem;
      @media(max-width: ${resolutions.tabletLarge}) {
        display: block;
      }
    }
    #logo {
      width: 75px;
      margin-left: 12px;
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 32px;
    .nav-link {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 24px;
      gap: 16px;
      &.active {
        background-color: ${functions.rgba(colors.primary, 0.1)};
        .icon {
          svg {
            path {
              stroke: ${colors.primary};
            }
          }
        }
        .text {
          color: ${colors.primary};
          font-weight: 600;
        }
      }
      .icon {
        width: 17px;
        height: 17px;
      }
      .text {
        font-size: 15px;
      }
    }
  }

  #userPanel {
    margin-top: auto;
    position: relative;
    .user {
      display: flex;
      gap: 10px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        &.placeholder {
          background-color: ${functions.colorLuminance(functions.randomColor, -0.5)};
          color: ${colors.white};
          text-transform: uppercase;
          font-size: 17px;
          ${snippets.flexCenter};
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        gap: 3px;
        &>* {
          width: fit-content;
        }
      }
    }
    .user-dropdown {
      position: absolute;
      top: -100%;
      transform: translate(50%, -50%);
      right: 50%;
      padding: 0.5rem;
      min-width: 7rem;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: ${colors.white};
      &:hover {
        background-color: ${colors.grey1};
      }
    }
  }
`;

export default Styles;